import React, {useEffect} from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { setupScroll } from "../../utils/scroll"
import { buildSeo, SITE_URL } from "../../utils/seo"
import {MDXComponents} from "../../components/components";
import {LeaveFragment} from "../../components/fragments/leave-fragment";

const MDXProvidersComponents = {...MDXComponents}

const ArticlePage = (data)  => {
  //const { slug, articleNode, previous, next } = pageContext
  const { articleNode } = data.pageContext;

  const { thumbnail, metaDescription, metaTitle} = articleNode.frontmatter;



  useEffect(( ) => {
    setupScroll();
  }, []);
 
  return ( 
    <div  className="page-content prestation-details">
      {buildSeo({
        title: metaTitle,
        description: metaDescription,
        image: `${SITE_URL}/images/${thumbnail}`
      })}
        <MDXProvider components={MDXProvidersComponents}>
            <MDXRenderer>{articleNode.body}</MDXRenderer>
            <LeaveFragment to={ "revenir aux prestations;" }/>
        </MDXProvider>
    </div>
  )
}
export default ArticlePage 
